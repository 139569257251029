#content {
	.location-details-hours {
		padding-left: .3rem !important;
		p {
			font-size: 1rem !important;
			line-height: 1.8rem !important; }
		ul {
			padding-left: 1rem;
			li {
				list-style: disc; } } }

	.aside {
		.position-center {
			text-align: center; } } }
